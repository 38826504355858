<template>
	<header class="z-50 w-full bg-color-main flex justify-between">
		<nuxt-link to="/pwa" class="z-50">
			<NuxtImg
				v-if="$isMobile.any"
				src="/shared-components-nuxt3/svgs/hokify/logo-hy-white.svg"
				alt="hokify"
				class="h-8 w-8 my-2 mx-5 inline-block"
			/>
			<NuxtImg
				v-else
				src="/shared-components-nuxt3/svgs/hokify/logo-brand-white.svg"
				alt="hokify"
				class="h-10 my-3 ml-12 mr-5 inline-block"
			/>
		</nuxt-link>
		<div
			v-if="isTestEnv"
			class="z-50 absolute bg-color-purple px-2 rounded-full text-color-white font-bold"
			style="font-size: x-small; left: 2.4rem; top: 0.3rem"
		>
			TEST
		</div>
		<nav id="main-nav" class="bg-color-main">
			<nuxt-link to="/pwa/" class="inline-block" @click="openFilterMenu">
				<span>
					<HokIcon class="header-icon inline-block md:mr-2" color="white" name="icon:swipecards" />
					<span class="hidden md:inline font-bold"> Jobsuche </span>
				</span>
			</nuxt-link>

			<nuxt-link to="/pwa/joblist" class="inline-block relative">
				<HokIcon class="header-icon inline-block md:mr-2" color="white" name="icon:chat" />
				<span v-if="unseenMsgs" class="badge">
					{{ unseenMsgs > 9 ? '9+' : unseenMsgs }}
				</span>
				<span v-else-if="unseenSavedJobs" class="badge notification-badge"> </span>
				<span class="hidden md:inline font-bold"> Meine Jobs </span>
			</nuxt-link>
			<nuxt-link to="/pwa/notifications" class="inline-block relative">
				<HokIcon
					class="header-icon inline-block md:mr-2"
					color="white"
					name="icon:alarm"
					:size="7"
				/>
				<span v-if="unseenNotifications" class="badge notification-badge" />
				<span class="hidden md:inline font-bold"> Mitteilungen </span>
			</nuxt-link>
			<nuxt-link v-if="loggedIn && user" to="/pwa/profile" class="inline-block relative">
				<NuxtImg
					v-if="user.images && user.images.small"
					:src="user.images.small"
					class="align-middle inline-block rounded-full object-cover w-8 h-8 md:mr-2 profile-image"
				/>
				<HokIcon
					v-else
					:size="7"
					class="header-icon inline-block md:mr-2"
					color="white"
					name="icon:profile"
				/>
				<span class="hidden lg:inline font-bold mr-3">
					{{ (user.general && user.general.firstName) || user.displayName }}
				</span>
				<span v-if="failedCvCriteriaCount > 0" class="badge"> ! </span>
			</nuxt-link>
		</nav>
	</header>
</template>

<script lang="ts">
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import type { IAPILoginUser } from '@hokify/common';
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useMessagesStore } from '@/stores/messages';
import { useLoginStore } from '@/stores/login';
import { useUserProfileStore } from '@/stores/user-profile';
import { useUiStateStore } from '@/stores/uistate';
import { useUserRootStore } from '@/stores/root';

export default defineComponent({
	data() {
		return { EventBus };
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore?.obj;
		},
		loggedIn() {
			return this.loginStore.loggedIn;
		},
		unseenSavedJobs() {
			return this.uiStateStore.unseenSavedJobs;
		},
		unseenNotifications() {
			return this.uiStateStore.unseenNotifications;
		},
		failedCvCriteriaCount() {
			return (
				(this.user &&
					this.user.reviewResult &&
					this.user.reviewResult.failedCvCriteria &&
					this.user.reviewResult.failedCvCriteria.length) ||
				0
			);
		},
		unseenMsgs() {
			return Object.keys(this.messagesStore.matchesWithUnseenMsgs ?? {}).length;
		},
		isTestEnv() {
			return process.env.cordova && this.$userRootStore?.host?.includes('test');
			// TODO enable dynamic env switching for cordova https://app.asana.com/0/1207203365740563/1208269806793009/f
			// return process.env.cordova && this.$axios?.defaults.baseURL?.includes('test');
		},
		...mapStores(
			useMessagesStore,
			useLoginStore,
			useUserProfileStore,
			useUiStateStore,
			useUserRootStore
		)
	},
	created() {
		if (import.meta.client) {
			if (window.history.length <= 1 && this.$refs.historyback instanceof Element) {
				this.$refs.historyback.classList.add('hidden');
			}
		}
	},
	methods: {
		openFilterMenu() {
			if (this.$route.name === 'pwa-jobNrOrJobIdOrCompanyId') {
				this.EventBus.$emit('open-filter-menu');
			}
		}
	}
});
</script>

<style scoped src="@hokify/pwa-core-nuxt3/assets/styles/header.scss" lang="scss"></style>
