<template>
	<div>
		<!-- header: always visible on desktop, on mobile only when there is no inside page open -->
		<HokHeader v-if="!$isMobile.any || !insidePageIsOpen" />
		<slot />
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { insidePages } from '@hokify/shared-components-nuxt3/lib/plugins/insidepage/insidePageService';
import HokHeader from '~/components/user/Header.vue';

export default defineComponent({
	name: 'UserLayout',
	components: { HokHeader },
	computed: {
		insidePageIsOpen(): boolean {
			return !!insidePages.pages?.length;
		}
	}
});
</script>
